import React, { useRef, useState } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import axios from '../axios';

export default function NewAd() {
  const nameRef = useRef()
  const descriptionRef = useRef()
  const positionRef = useRef()
  const linkRef = useRef()
  const imageUrlRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  
  async function handleSubmit(e) {
    e.preventDefault()
    const requestBody = {
      title: nameRef.current.value,
      description: descriptionRef.current.value,
      position: positionRef.current.value,
      link: linkRef.current.value,
      imageURL: imageUrlRef.current.value,
      approved: true
    }
    try {
      axios.post(`/ads`, requestBody).then(res => {
        history.push("/ads");
      });
    }catch {
      setError("Failed to add ad");
      setLoading(false)
    }
  }

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <div className="w-100">
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">New Ad</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                ref={nameRef}
                required
              />
            </Form.Group>
            <Form.Group id="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                ref={descriptionRef}
                required
              />
            </Form.Group>

            <Form.Group controlId="position">
              <Form.Label>Position</Form.Label>
              <Form.Control as="select" ref={positionRef}>
                <option>middle</option>
                <option>top</option>
              </Form.Control>
            </Form.Group>
            <Form.Group id="link">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                ref={linkRef}
                required
              />
            </Form.Group>
            <Form.Group id="imageURL">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                ref={imageUrlRef}
                required
              />
            </Form.Group>
            
            <Button disabled={loading} className="w-100" type="submit">
              Add
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/ads">Cancel</Link>
      </div>
    </div>
    </Row>
   </div>
  )
}
