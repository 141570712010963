import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import axios from '../axios';

export default function NewSubCategory() {
  const nameRef = useRef()
  const descriptionRef = useRef()
  const optionsRef = useRef();
  const ageOptionsRef = useRef();
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [tags, setTags] = useState([])
  const [ageOptions, setAgeOptions] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [category, setCategory] = useState({})
  const [brand, setBrand] = useState({})
  const [subcategory, setSubcategory] = useState({})
  
  const isEditPage  =  window.location.pathname.split('/').length === 5 && window.location.pathname.split('/')[3] !== 'subcategory_new' ;
  const categoryId =  window.location.pathname.split('/')[2]
  useEffect(() => {
      axios.get(`/category/${categoryId}`).then(res => {
        setCategory(res.data)
      });
      if(isEditPage) {
        const subcategoryId =  window.location.pathname.split('/')[3]
        axios.get(`/category/subcategory?categoryId=${categoryId}&subcategoryId=${subcategoryId}`).then(res => {
          setSubcategory(res.data)
          setTags(res.data.options)
          setAgeOptions(res.data.ages? res.data.ages: [] )
        });
      }
  }, []);

  const removeTag = (i) => {
    const newTags = [ ...tags ];
    newTags.splice(i, 1);
    setTags(newTags);
  }
  const removeAgeOption = (i) => {
    const newAgeOptions = [ ...ageOptions ];
    newAgeOptions.splice(i, 1);
    setAgeOptions(newAgeOptions);
  }

  const inputKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      if (tags.find(tag => tag.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setTags( [...tags, val]);
      optionsRef.current.value = null;
    } else if (e.key === 'Backspace' && !val) {
      removeTag(tags.length - 1);
    }
  }
  const inputAgeKeyDown = (e) => {
    const val = e.target.value;
    if (e.key === 'Enter' && val) {
      if (ageOptions.find(age => age.toLowerCase() === val.toLowerCase())) {
        return;
      }
      setAgeOptions( [...ageOptions, val]);
      ageOptionsRef.current.value = null;
    } else if (e.key === 'Backspace' && !val) {
      removeAgeOption(ageOptions.length - 1);
    }
  }
  async function handleSubmit(e) {
    e.preventDefault();
    const requestBody = {
      title: nameRef.current.value,
      description: descriptionRef.current.value,
      options: tags,
      ages: ageOptions
    }
   
    
    if(isEditPage){
      category.subcategory.map((sub) => {
        const subcategoryId =  window.location.pathname.split('/')[3];
        if(sub.id === subcategoryId){
            sub.title = nameRef.current.value;
            sub.description = descriptionRef.current.value;
            sub.options = tags;
            sub.ages= ageOptions;
            
        }
        return sub;
      });
      try {
        axios.patch(`/category/${categoryId}`, category).then(res => {
          history.push("/category");
        });
      }catch {
        setError("Failed to add category");
        setLoading(false)
      }
    }else{
      category.subcategory.push(requestBody);
      try {
        axios.patch(`/category/${categoryId}`, category).then(res => {
          history.push("/category");
        });
      }catch {
        setError("Failed to add category");
        setLoading(false)
      }
    }
  }

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <div className="w-100">
      <Card>
        <Card.Body>
        <h2 className="text-center mb-4"> {category.title}</h2>
        <hr/>
          <h2 className="text-center mb-4"> {isEditPage ? 'Update Subcategory' : 'New Subcategory'}</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form  >
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                ref={nameRef}
                required
                defaultValue={subcategory.title}
              />
            </Form.Group>
            <Form.Group id="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                ref={descriptionRef}
                required
                defaultValue={subcategory.description}
              />
            </Form.Group>
              <Form.Group id="options">
              <Form.Label>Options</Form.Label>
                <div className="input-tag">
                  <ul className="input-tag__tags">
                    { tags.map((tag, i) => (
                      <li key={tag}>
                        {tag}
                        <button type="button" onClick={() => removeTag(i)}>+</button>
                      </li>
                    ))}
                    <li className="input-tag__tags__input"><input type="text" onKeyDown={(e) => inputKeyDown(e)} ref={optionsRef} /></li>
                  </ul>
                </div>
                </Form.Group>

                <Form.Group id="sub_options">
              <Form.Label>Age Options</Form.Label>
                <div className="input-tag">
                  <ul className="input-tag__tags">
                    { ageOptions.map((age, i) => (
                      <li key={age}>
                        {age}
                        <button type="button" onClick={() => removeAgeOption(i)}>+</button>
                      </li>
                    ))}
                    <li className="input-tag__tags__input"><input type="text" onKeyDown={(e) => inputAgeKeyDown(e)} ref={ageOptionsRef} /></li>
                  </ul>
                </div>
                </Form.Group>
            
            
            <Button disabled={loading} className="w-100" type="button" onClick={(e) =>handleSubmit(e)}>
              {isEditPage ? 'Update' : 'Add'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/brands">Cancel</Link>
      </div>
    </div>
    </Row>
   </div>
  )
}
