import React, { useRef, useState,useEffect } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom";

import axiosOne from '../axios';
import axios from 'axios';

export default function NewNotification() {
  const nameRef = useRef()
  const descriptionRef = useRef()
  const linkRef = useRef()
  const imageUrlRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [token, setToken] = useState()
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const userId = params.get('userId');
    if(userId){
        axiosOne.get(`/users/${userId}`).then(res => {
          const user = res.data;
          setToken(user.notificationToken);
        });
    }
    
  }, []);
  
  async function handleSubmit(e) {
    e.preventDefault()
    if(token){
       const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAAVFh8Vd4:APA91bEluMMDPdS85s7y-qIBG6VeBn-ovCAQAq8xczlqI7ks1lISOW8QeJ2KMbyQn8nsMftxpui_T3WgP3Wb9GcbxBopw-SAVh7t8EaMfW4s2Y-Q3W6ksHb_-t350hywBOfh0OqPBKJb'
              }
            const data = {
                to : token,
                collapse_key : "Mom-tah Notifications",
                priority: "high",
                notification : {
                  title: nameRef.current.value,
                  body: descriptionRef.current.value,
                }
            }
            if(token){ 
              axios.post("https://fcm.googleapis.com/fcm/send", data,  {
                headers: headers
              })
              .then((response) => {
                history.push("/users");
              })
              .catch((error) => {
                console.log(error)
              })
            }

    }else{
      const requestBody = {
        title: nameRef.current.value,
        description: descriptionRef.current.value,
        link: linkRef.current.value,
        imageURL: imageUrlRef.current.value,
        approved: true,
        isSeen: false,
        isGeneral: true,
        ownerId:"mom-tah"
      }
      try {
        axios.post(`/notification`, requestBody).then(res => {
          // send push notifications to all devices
          history.push("/notifications");
        });
      }catch {
        setError("Failed to add notification");
        setLoading(false)
      }
    }
  }

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <div className="w-100">
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">New Notification</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                ref={nameRef}
                required
              />
            </Form.Group>
            <Form.Group id="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                ref={descriptionRef}
                required
              />
            </Form.Group>

           
            <Form.Group id="link">
              <Form.Label>Link</Form.Label>
              <Form.Control
                type="text"
                ref={linkRef}
                required
              />
            </Form.Group>
            <Form.Group id="imageURL">
              <Form.Label>Image URL</Form.Label>
              <Form.Control
                type="text"
                ref={imageUrlRef}
                required
              />
            </Form.Group>
            
            <Button disabled={loading} className="w-100" type="submit">
              Add
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/notifications">Cancel</Link>
      </div>
    </div>
    </Row>
   </div>
  )
}
