import React, { useState, useEffect } from "react"
import { Card,Row, Col, Container, Button, Alert, Table, Image , Pagination} from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { storageRef } from "../firebase";

import axiosOne from '../axios';
import axios from 'axios';
export default function Product() {
  const [error, setError] = useState("")
  const [copied, setCopied] = useState(false)
  const [products, setProducts] = useState([])
  const { currentUser, logout } = useAuth()
  const [totalPage, setTotalPage] = useState();
  const history = useHistory()
  const [active, setActive] = useState(1);
  const [items, setItems] = useState([])
  

  useEffect(() => {
    axiosOne.get(`/product/v2?page=1&limit=10`).then(res => {
      const {data: {data, totalCount, page, limit}} = res;
      let paginationItems = []
      const totalNumbers = (Math.ceil(totalCount/10));
      for (let number = 1; number <= totalNumbers; number++) {
        paginationItems.push(
          <Pagination.Item key={number} active={number === active} onClick={() => handlePaginaion(number)}>
            {number}
          </Pagination.Item>,
        );
      }

      setItems(paginationItems)
      setProducts(data);
  
    });
  }, []);

  

const paginationBasic  =  (
  <div>
    <Pagination>{items}</Pagination>
  </div>
);



  async function removeFile(downloadUrl) {
  const storageRefa = storageRef.refFromURL(downloadUrl);
  storageRefa.delete().then((response) => {
    console.log('delete response', response);
  }).catch((error) => {
    console.log('delete error', error)
  });
}
  async function handlePaginaion(pageNumber) {
    axiosOne.get(`/product/v2?page=${pageNumber}&limit=10`).then(res => {
      const {data: {data, totalCount, page, limit}} = res;
      
      let paginationItems = []
      const totalNumbers = (Math.ceil(totalCount/10));
      for (let number = 1; number <= totalNumbers; number++) {
        paginationItems.push(
          <Pagination.Item key={number} active={number === pageNumber} onClick={() => handlePaginaion(number)}>
            {number}
          </Pagination.Item>,
        );
      }

      setItems(paginationItems)
      setProducts(data);
      
    });
  }
  async function handleDelete(productId, imageURL) {
    
    try {
      axiosOne.delete(`/product/${productId}`).then(res => {
        const newproducts = products.map(product => {
          if(product.id !== productId){
            return product;
            }
          }
        ).filter(item => item);
        setProducts(newproducts);
      });
    }catch {
      setError("Failed to delete product")
    }
    await removeFile(imageURL);
  }

  async function handleApprove(product) {
    try {
      product.status = 'Approved';
      axiosOne.patch(`/product/${product.id}`, product).then(res => {
        
        axiosOne.get(`/users/${product.ownerId}`).then(res => {
            const { notificationToken, username } = res.data;
            const headers = {
                'Content-Type': 'application/json',
                'Authorization': 'key=AAAAVFh8Vd4:APA91bEluMMDPdS85s7y-qIBG6VeBn-ovCAQAq8xczlqI7ks1lISOW8QeJ2KMbyQn8nsMftxpui_T3WgP3Wb9GcbxBopw-SAVh7t8EaMfW4s2Y-Q3W6ksHb_-t350hywBOfh0OqPBKJb'
              }
            const data = {
                to : notificationToken,
                collapse_key : "Mom-tah Notifications",
                priority: "high",
                notification : {
                  title: `${product.title} Approved`,
                  body: "This is to notify you that your product has been approved"
                }
            }
            if(notificationToken){ 
              axios.post("https://fcm.googleapis.com/fcm/send", data,  {
                headers: headers
              })
              .then((response) => {
                console.log(response)
              })
              .catch((error) => {
                console.log(error)
              })
            }
         });
        
        axiosOne.post(`/product/broadcast/${product.id}`, {}).then(res => console.log(res) )
      });
    }catch {
      setError("Failed to approve product")
    }
  }

   async function togglePoplular(product) {
    try {
      product.isPopular = !product.isPopular;
      axiosOne.patch(`/product/${product.id}`, product).then(res => {

      });
    }catch {
      setError("Failed to update product")
    }
  }

  
  const list = products.map((product, index) => {
    const copyText= `
    Name: ${product.title}
    Description: ${product.description}
    Price: Rs. ${product.price}
    Location: ${product.location}
    Size: ${product.size}
    Rating: ${product.rating} of 5
    ${product.images[0]}`;
    const humanizeDate = new Date(product.createdOn).toDateString();
    return (
      <tr key={index}>
        <td>{product.ownerId}</td>
        <td>{product.title}</td>
        <td>{product.description}</td>
        <td>{product.price}</td>
        <td>{product.location}</td>
        <td>{product.size}</td>
        <td>{product.rating}</td>
        <td><Image src={product.images[0].replace(/upload/g, "upload/w_1.0,c_scale")} thumbnail className="product-image"/></td>
        <td>{product.isPopular ? 'Popular': 'Regular'}</td>
        <td>{product.status}</td>
        <td>{humanizeDate}</td>
        <td className={'button-actions img'}>   <CopyToClipboard text={copyText}
          onCopy={() => setCopied(true)}>
          <Button > Copy </Button > 
        </CopyToClipboard> 
        <Button variant="success" onClick={() => handleApprove(product)}>Approve</Button> {'    '}&nbsp;
        <Button variant="info" onClick={() => togglePoplular(product)}>{product.isPopular ? 'Popular': 'Regular'} </Button> {'    '} &nbsp;
  <Button variant="danger" onClick={() => handleDelete(product.id, product.images[0])}> {' '}Delete</Button>{' '}
  </td>
    </tr>
    )
  })

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <h1> Products </h1>
   
    <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Description</th>
      <th>Price</th>
      <th>Location</th>
      <th>Size</th>
      <th>Rating</th>
      <th>Image</th>
      <th>Popular</th>
      <th>Status</th>
      <th>Posted At </th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
      {list}
  </tbody>
</Table>
    {paginationBasic}
    <br/><br/><br/>
    </Row>
    
    </div>
  )
}
