import React from 'react';
import { useIntl } from 'react-intl';
import Switch from 'react-switch';
import { FaHeart, FaBars } from 'react-icons/fa';
import { Link, useHistory } from "react-router-dom"
import Profile from "./Profile"
import Product from "./Product"
import User from "./User"
import Donations from "./Donations"
import UpdateProfile from "./UpdateProfile"
import Ads from "./Ads"
import NewAd from "./NewAd"
import Brands from "./Brands"
import NewBrand from "./NewBrand"
import NewDonation from "./NewDonation"
import Category from "./Category"
import NewSubCategory from "./NewSubCategory"
import Notifications from  "./Notifications"
import NewNotification from "./NewNotification"


const Main = ({
  collapsed,
  rtl,
  image,
  handleToggleSidebar,
  handleCollapsedChange,
  handleRtlChange,
  handleImageChange,
}) => {
  const intl = useIntl();
  const history = useHistory()
   const renderComponent = () => {
    let { location : {
      pathname
    }} = history;
      if(window.location.pathname.split('/').length >= 4){
        const mainUrl = window.location.pathname.split('/')[1];
        if(mainUrl === 'brands'){
          pathname = 'brandsEdit'
        }else if(mainUrl === 'category') {
          pathname = 'newSubcategory'
        }else if(mainUrl === 'donations'){
          pathname = 'donationEdit'
        }
      }
      switch (pathname) { 
        case '/donations':
          return <Donations/>;
          break;
        case '/donations/new':
          return <NewDonation/>
          break;
        case 'donationEdit':
          return <NewDonation/>
          break;
        case '/update-profile':
          return <UpdateProfile/>;
          break;
        case '/profile':
          return <Profile/>;
          break;
        case '/products':
          return <Product/>
          break;
        case '/users':
          return <User/>
          break;
        case '/ads':
          return <Ads/>
          break;
        case '/ads/new':
          return <NewAd/>
          break;
        case '/brands':
          return <Brands/>
          break;
        case '/brands/new':
          return <NewBrand/>
          break;
        case 'brandsEdit':
          return <NewBrand/>
          break;
        case '/category':
          return <Category/>
          break;
        case 'newSubcategory':
          return <NewSubCategory/>
          break;
        case '/notifications':
          return <Notifications/>
          break;
        case '/notifications/new':
          return <NewNotification/>
          break;
        default:
          return <Product/>;
      }
      // if (pathname === '/donations') {
      //   return <UpdateProfile/>;
      // } else {
      //   return <button>Login</button>;
      // }
    }
  return (
    <main >
     <div className="btn-toggle" onClick={() => handleToggleSidebar(true)}>
        <FaBars />
      </div>
      <div className="main-container">
      {renderComponent()}
      </div>
    </main>
  );
};

export default Main;