import axios from 'axios';
import { constants } from "./constants"

const { baseURL } = constants;
const httpClient = axios.create({
    baseURL
});

httpClient.interceptors.request.use(function (config) {
    const token = localStorage.getItem('token');
    config.headers.Authorization =  token ? `Bearer ${token}` : '';
    return config;
});

export default httpClient;