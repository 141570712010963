import React, { useRef, useState, useEffect} from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import axios from '../axios';

export default function NewDonation() {
  const nameRef = useRef()
  const descriptionRef = useRef()
  
  const preferredHourRef = useRef()
  const locationRef = useRef()
  const footPrintsRef = useRef()
  const collectionDateRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [donation, setDonation] = useState({})

  const isEditPage  =  window.location.pathname.split('/').length === 4;
  const donationId =  window.location.pathname.split('/')[2]
  useEffect(() => {
    if(isEditPage){
      axios.get(`/donation/search?donationId=${donationId}`).then(res => {
          setDonation(res.data)
      });
    }
  }, []);
  
  async function handleSubmit(e) {
    e.preventDefault()
    const requestBody = {
      donorName: nameRef.current.value,
      description: descriptionRef.current.value,
      location: locationRef.current.value,
      prefferedHour: preferredHourRef.current.value,
      imageURL: "https://momtah.web.app/img/boudhanath_qrcode.png",
      ownerId : "123",
      footPrints: footPrintsRef.current.value,
      approved: true,
      collectionDate: collectionDateRef.current.value
    }
     if(isEditPage){
      try {
        axios.patch(`/donation/${donationId}`, requestBody).then(res => {
          history.push("/donations");
        });
      }catch {
        setError("Failed to update donation");
        setLoading(false)
      }
    }else{
      try {
        axios.post(`/donation`, requestBody).then(res => {
          history.push("/donations");
        });
      }catch {
        setError("Failed to add donation");
        setLoading(false)
      }
    }
  }

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <div className="w-100">
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4">{isEditPage ? 'Update Donation' : ' New Donation'}</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="name">
              <Form.Label>Donor name</Form.Label>
              <Form.Control
                type="text"
                ref={nameRef}
                required
                defaultValue={donation.donorName}
              />
            </Form.Group>
            <Form.Group id="description">
              <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                ref={descriptionRef}
                required
                defaultValue={donation.description}
              />
            </Form.Group>

            
            <Form.Group id="location">
              <Form.Label>Location</Form.Label>
              <Form.Control
                type="text"
                ref={locationRef}
                required
                defaultValue={donation.location}
              />
            </Form.Group>
            <Form.Group id="preferredHourRef">
              <Form.Label>Preferred Hour</Form.Label>
              <Form.Control
                type="text"
                ref={preferredHourRef}
                required
                defaultValue={donation.prefferedHour}
              />
            </Form.Group>
             <Form.Group id="footPrintsRef">
              <Form.Label>Carbon Footprints Saved</Form.Label>
              <br/>
              <span style={{fontSize: "12px"}}><b>Note:</b> 1 kg of garment material has a carbon footprint of 7.82 kg CO2e  </span>
              <Form.Control
                type="text"
                ref={footPrintsRef}
                placeholder="200"
                required
                defaultValue={donation.footPrints}
              />
            </Form.Group>
            <Form.Group id="collectionDateRef">
              <Form.Label>Collection Date</Form.Label>
              <Form.Control
                type="text"
                placeholder="21/08/2021"
                ref={collectionDateRef}
                required
                defaultValue={donation.collectionDate}
              />
            </Form.Group>
            <Button disabled={loading} className="w-100" type="submit">
              {isEditPage ? 'Update' : 'Add'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/donations">Cancel</Link>
      </div>
    </div>
    </Row>
   </div>
  )
}
