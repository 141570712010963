import React, { useState, useEffect } from "react"
import { Card,Row, Col, Container, Button, Alert, Table, Image } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import axios from '../axios';

export default function Ads() {
  const [error, setError] = useState("")
  const [ads, setAds] = useState([])
  const { currentUser, logout } = useAuth()
  const history = useHistory()


  useEffect(() => {
    axios.get(`/ads`).then(res => {
      const ads = res.data;
      setAds(ads)
    });
  }, []);


  async function handleDelete(adId) {
    try {
      axios.delete(`/ads/${adId}`).then(res => {
        const newads = ads.map(ad => {
          if(ad.id !== adId){
            return ad;
            }
          }
        ).filter(item => item);
        setAds(newads);
      });
    }catch {
      setError("Failed to delete ad")
    }
  }

  async function newAd(){
    history.push("/ads/new")
  }
  async function handleApprove(ad) {
    try {
      ad.approved = true;
      axios.patch(`/ads/${ad.id}`, ad).then(res => {
        const updatedAds = ads.map(_ad => {
          if(ad.id  === _ad.id){
            _ad.approved = true;
          }
          return _ad;
        }).filter(item => item);
        setAds(updatedAds);
      });
    }catch {
      setError("Failed to delete ad")
    }
  }

  
  const list = ads.map((ad, index) => {
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>{ad.title}</td>
        <td>{ad.description}</td>
        <td>{ad.position}</td>
        <td> <a href={ad.link} target="_blank"> {ad.link}</a></td>
        <td><Image src={ad.imageURL} thumbnail className="product-image"/></td>
        <td>{ad.approved ? 'Approved' : 'Not approved'}</td>
        <td className={'button-actions img'}>  <Button variant="success" onClick={() => handleApprove(ad)}>Approve</Button> {'    '}
  <Button variant="danger" onClick={() => handleDelete(ad.id)}>Delete</Button>{' '}</td>
    </tr>
    )
  })

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <Row className="top-header">
    <h1> Ads </h1>

   <Button variant="success" onClick={newAd}>New Ad</Button>
   </Row>
    <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Description</th>
      <th>Position</th>
      <th>Link</th>
      <th>Image</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
      {list}
  </tbody>
</Table>
    </Row>
    </div>
  )
}
