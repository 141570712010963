import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore";
import "firebase/storage";

const app = firebase.initializeApp({
  apiKey: "AIzaSyDiJQRmmiCdSmA7rKefXIjkdB_9olDXAOE",
  authDomain: "mom-ta.firebaseapp.com",
  databaseURL: "https://mom-ta-default-rtdb.firebaseio.com",
  projectId: "mom-ta",
  storageBucket: "mom-ta.appspot.com",
  messagingSenderId: "362261796318",
  appId: "1:362261796318:web:5add64073345de528c394f",
  measurementId: "G-9GKLWQV409"
})

export const auth = app.auth()
export const firestore = firebase.firestore();
export const storageRef = firebase.storage();
export default app
