import React from 'react';
import { useIntl } from 'react-intl';
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent,
} from 'react-pro-sidebar';
import { FaTachometerAlt, FaGem, FaList, FaGithub, FaRegLaughWink, FaHeart } from 'react-icons/fa';
// import sidebarBg from './assets/bg1.jpg';
import { Card,Row, Col, Container, Button, Alert } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import PrivateRoute from "./PrivateRoute"
import { Link, useHistory } from "react-router-dom"
import { AuthProvider } from "../contexts/AuthContext"

import 'react-pro-sidebar/dist/css/styles.css';
import { FaUser, FaMailBulk , FaClipboardList, FaClipboard, FaRecycle } from 'react-icons/fa';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Profile from "./Profile"
import Product from "./Product"
import Donations from "./Donations"
import UpdateProfile from "./UpdateProfile"
import Ads from "./Ads"
import NewAd from "./NewAd"
import Brands from "./Brands"
import NewBrand from "./NewBrand"
import Category from "./Category"
import NewSubCategory from "./NewSubCategory"
import Notifications from  "./Notifications"
import NewNotification from "./NewNotification"
const Aside = ({ image, collapsed, rtl, toggled, handleToggleSidebar }) => {
  const intl = useIntl();
  return (
    <ProSidebar
      image={ false}
      rtl={rtl}
      collapsed={collapsed}
      toggled={toggled}
      breakPoint="md"
      onToggle={handleToggleSidebar}
    >
    <SidebarHeader>
    <Menu iconShape="square">
    <MenuItem icon={<FaRecycle/>}>
     Mom-ta
    </MenuItem>
    </Menu>
    </SidebarHeader>

      <SidebarContent>
      <Menu iconShape="square">
        <MenuItem icon={<FaHeart />}>  <Link to="/donations"> Donations </Link></MenuItem>
        <MenuItem icon={<FaClipboardList />}>  <Link to="/brands"> Brands </Link></MenuItem>
        <MenuItem icon={<FaClipboard />}>  <Link to="/category"> Category </Link></MenuItem>
        <MenuItem icon={<FaClipboardList />}>  <Link to="/products"> Products </Link></MenuItem>
        <MenuItem icon={<FaClipboardList />}>  <Link to="/ads"> Ads </Link></MenuItem>
        <MenuItem icon={<FaClipboardList />}>  <Link to="/users"> Users </Link></MenuItem>
        <MenuItem icon={<FaMailBulk />}>  <Link to="/notifications"> Notifications </Link></MenuItem>
      </Menu>
        </SidebarContent>
        <SidebarFooter>
        <Menu iconShape="square">
          <MenuItem icon={<FaUser />}>  <Link to="/profile"> Profile </Link></MenuItem>
        </Menu>
        </SidebarFooter>
    </ProSidebar>
  );
};

export default Aside;