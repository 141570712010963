import React, { useState, useEffect } from "react"
import { Card,Row, Col, Container, Button, Alert, Table, Image, Tabs, Tab} from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import axios from '../axios';

export default function Category() {
  const [error, setError] = useState("")
  const [categories, setCategories] = useState([])
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  
  const products = [];
  useEffect(() => {
    axios.get(`/category`).then(res => {
      const categories = res.data;
      setCategories(categories)
    });
  }, []);


  async function handleDelete(categoryId, subcategoryId) {

      let category = categories.find(category => category.id == categoryId);
      if(category){
        const subs =  category.subcategory.map((sub) => {
            if(sub.id === subcategoryId){}else{
              return  sub;
            }
          }).filter(item => item);
          category.subcategory = subs;
          try {
            axios.patch(`/category/${categoryId}`, category).then(res => {
              history.push("/category");
            });
          }catch {
            setError("Failed to delete category");
          }
      }
    // try {
    //   axios.delete(`/product/${productId}`).then(res => {
    //     const newproducts = products.map(product => {
    //       if(product.id !== productId){
    //         return product;
    //         }
    //       }
    //     ).filter(item => item);
    //     setProducts(newproducts);
    //   });
    // }catch {
    //   setError("Failed to delete product")
    // }

  }
  async function newSubcategory(categoryId){
      history.push(`/category/${categoryId}/subcategory_new`)
  }
  async function editSubcategory(categoryId, subcategoryId) {
      history.push(`/category/${categoryId}/${subcategoryId}/subcategory_edit`);
  }

  
  const list = (categoryId, subcategory) => {
    return (
    <>
    {subcategory.map((sub, index) => {
      return (<tr key={index}>
        <td>{index+1}</td>
        <td>{sub.title}</td>
        <td>{sub.description}</td>
        <td>{sub.options.map((option, ind) =>{ return (<Button key={`option-${ind}`} style={{margin:2}} variant="primary" size="sm">{option}</Button>)})}</td>
        <td>{sub.ages?.map((age, ind) =>{ return (<Button key={`age-${ind}`} style={{margin:2}} variant="primary" size="sm">{age}</Button>)})}</td>
        <td className={'button-actions'}>  <Button variant="success" onClick={() => editSubcategory(categoryId, sub.id)}>Edit</Button> {'    '}
        <Button variant="danger" onClick={() => handleDelete(categoryId, sub.id)}>Delete</Button>{' '}</td>

    </tr>)
    })
    }
   </>
  )
  }

  const table = (categoryId, subcategory) => {
      return (
        <Row style={{ minWidth: "30vw" }}>
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Description</th>
              <th>Options</th>
              <th>Age Options</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
              {list(categoryId, subcategory)}
          </tbody>
        </Table>
        </Row>
      )
  }
  
  

  return (
  <Container>
    <Row style={{ minWidth: "30vw" }}>
    <h1> Category </h1>
    <br/>
    </Row>
    <Row style={{ minWidth: "30vw" }}>
     {categories.length > 0 ?
        <Tabs transition={false} defaultActiveKey={categories[0].title} id="uncontrolled-tab-example"  >
          {categories.map((category, index) => {
          return (
            <Tab eventKey={category.title} key={index} title={category.title}>
            <Container>
              <Row> 
              
                  <Col md={{ offset: 10 }} className={'tab-new-button'}>
                  <Button variant="success"  onClick={() => newSubcategory(category.id)}>New Subcategory</Button>
                </Col>
              </Row>
              <Row style={{ minWidth: "30vw" }}>

                {table(category.id, category.subcategory)}
              </Row>
            </Container>
            </Tab>
              )
            })
          }
        </Tabs>
      : null }
    </Row>
    </Container>
  )
}
