import React, { useRef, useState, useEffect } from "react"
import { Form, Button, Card, Alert, Row, Col } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import axios from '../axios';

export default function NewAd() {
  const nameRef = useRef()
  const { currentUser, updatePassword, updateEmail } = useAuth()
  const [error, setError] = useState("")
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const [brand, setBrand] = useState({})
  
  const isEditPage  =  window.location.pathname.split('/').length === 4;
  const brandsId =  window.location.pathname.split('/')[2]
  useEffect(() => {
    if(isEditPage){
      axios.get(`/brands/${brandsId}`).then(res => {
        
        setBrand(res.data)
      });
    }
  }, []);
  async function handleSubmit(e) {
    e.preventDefault()
    const requestBody = {
      title: nameRef.current.value
    }
    if(isEditPage){
      try {
        axios.patch(`/brands/${brandsId}`, requestBody).then(res => {
          history.push("/brands");
        });
      }catch {
        setError("Failed to add brand");
        setLoading(false)
      }
    }else{
    try {
      axios.post(`/brands`, requestBody).then(res => {
        history.push("/brands");
      });
    }catch {
      setError("Failed to add brand");
      setLoading(false)
    }
    }
  }

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <div className="w-100">
      <Card>
        <Card.Body>
          <h2 className="text-center mb-4"> {isEditPage ? 'Update Brand' : 'New Brand'}</h2>
          {error && <Alert variant="danger">{error}</Alert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group id="name">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                ref={nameRef}
                required
                defaultValue={brand.title}
              />
            </Form.Group>
            
            
            <Button disabled={loading} className="w-100" type="submit">
              {isEditPage ? 'Update' : 'Add'}
            </Button>
          </Form>
        </Card.Body>
      </Card>
      <div className="w-100 text-center mt-2">
        <Link to="/brands">Cancel</Link>
      </div>
    </div>
    </Row>
   </div>
  )
}
