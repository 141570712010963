import React, { useState, useEffect } from "react"
import { Card,Row, Col, Container, Button, Alert, Table, Image } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { storageRef } from "../firebase";

import axiosOne from '../axios';
import axios from 'axios';
export default function User() {
  const [error, setError] = useState("")
  const [copied, setCopied] = useState(false)
  const [users, setUsers] = useState([])
  const { currentUser, logout } = useAuth()
  const history = useHistory()
  

  useEffect(() => {
    axiosOne.get(`/users`).then(res => {
      const users = res.data;
      setUsers(users)
    });
  }, []);

  async function sendMessage(userId) {
      history.push(`/notifications/new?userId=${userId}`);
    }
  
  const list = users.map((user, index) => {
    
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>{user.id}</td>
        <td>{user.username}</td>
        <td>{user.email}</td>
        <td>{user.notificationToken ? 'Available' : 'Nil'}</td>
        <td><Image src={user.photoURL.replace(/upload/g, "upload/w_1.0,c_scale")} thumbnail className="product-image"/></td>
        <td className={'button-actions img'}>   
         {user.notificationToken &&
            <Button variant="info" onClick={() => sendMessage(user.username)}> {' '} Send Message</Button>
         }
        </td>
    </tr>
    )
  })

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <h1> Users </h1>
   
    <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Id</th>
      <th>Username</th>
      <th>Email</th>
      <th>Notification Token</th>
      <th>Image</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
      {list}
  </tbody>
</Table>
    </Row>
    </div>
  )
}
