import React, { useState, useEffect } from "react"
import { Card,Row, Col, Container, Button, Alert, Table, Image } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"
import axios from '../axios';

export default function Brands() {
  const [error, setError] = useState("")
  const [brands, setBrands] = useState([])
  const { currentUser, logout } = useAuth()
  const history = useHistory()

  useEffect(() => {
    axios.get(`/brands`).then(res => {
      const brands = res.data;
      setBrands(brands)
    });
  }, []);


  async function handleDelete(brandId) {
    try {
      axios.delete(`/brands/${brandId}`).then(res => {
        const newbrands = brands.map(brand => {
          if(brand.id !== brandId){
            return brand;
            }
          }
        ).filter(item => item);
        setBrands(newbrands);
      });
    }catch {
      setError("Failed to delete ad")
    }
  }

  async function newAd(){
    history.push("/brands/new")
  }
  async function editBrand(brandId) {
    history.push(`/brands/${brandId}/edit`)
  }

  
  const list = brands.map((brand, index) => {
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>{brand.title}</td>
        <td className={'button-actions'}>  <Button variant="success" onClick={() => editBrand(brand.id)}>Edit</Button> {'    '}
        <Button variant="danger" onClick={() => handleDelete(brand.id)}>Delete</Button>{' '}</td>
    </tr>
    )
  })

  return (
    <Container>
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
  
    <Row style={{ minWidth: "30vw" }}>
    <Row className="top-header">
    
    <h1> Brands </h1>
   <Button variant="success" onClick={newAd}>New Brand</Button>
   </Row>
    <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
      {list}
  </tbody>
</Table>
    </Row>
    
    </div>
    </Container>
  )
}
