import React, { useState, useEffect } from "react"
import { Card,Row, Col, Container, Button, Alert, Table, Image } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import axios from '../axios';

export default function Notifications() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const [notifications, setNotifications] = useState([])
  
  const history = useHistory()

   useEffect(() => {
    axios.get(`/notification`).then(res => {
      const notifications = res.data;
      setNotifications(notifications)
    });
  }, []);


  async function handleDelete(notificationId) {
    try {
      axios.delete(`/notification/${notificationId}`).then(res => {
        const newnotifications = notifications.map(notification => {
          if(notification.id !== notificationId){
            return notification;
            }
          }
        ).filter(item => item);
        setNotifications(newnotifications);
      });
    }catch {
      setError("Failed to delete notification")
    }
  }
   
   async function newNotification(){
    history.push("/notifications/new")
  }

   const list = notifications.map((notification, index) => {
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>{notification.title}</td>
        <td>{notification.description}</td>
        <td><a href={notification.link} target="_blank"> {notification.link}</a></td>
        <td>{notification.isSeen ? 'Seen' : 'Not Seen'}</td>
        <td>{notification.isGeneral ? 'General' : 'Not General'}</td>
        <td><Image src={notification.imageURL} thumbnail className="product-image"/></td>
        <td>{notification.approved ? 'Approved' : 'Not approved'}</td>
          <td className={'button-actions img'}>  
  <Button variant="danger" onClick={() => handleDelete(notification.id)}>Delete</Button>{' '}</td>
    </tr>
    )
  })

  return (
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
     <Row className="top-header">
        <h1> Notifications </h1>

      <Button variant="success" onClick={newNotification}>New Notification</Button>
      </Row>
   
    <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Description</th>
      <th>Link</th>
      <th>Seen</th>
      <th>Notification Type</th>
      <th>Image</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
      {list}
  </tbody>
</Table>
    </Row>
    </div>
  )
}
