import React, { useState } from 'react';
import Signup from "./Signup"
import { Container } from "react-bootstrap"
import { AuthProvider } from "../contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import Dashboard from "./Dashboard"
import { IntlProvider } from 'react-intl';
import Login from "./Login"
import PrivateRoute from "./PrivateRoute"
import ForgotPassword from "./ForgotPassword"
import messages from './messages';
function App() {
  const [locale, setLocale] = useState('en');
  return (
   <IntlProvider locale={locale} messages={messages[locale]}>
    <Container
      className="d-flex app-wrapper"
      style={{ minHeight: "100vh" }}
      fluid
    >
        <Router>
          <AuthProvider>
            <Switch>
              <PrivateRoute exact path="/" component={Dashboard} />
              <PrivateRoute exact path="/update-profile" component={Dashboard} />
              <PrivateRoute exact path="/donations" component={Dashboard} />
              <PrivateRoute exact path="/donations/new" component={Dashboard} />
              <PrivateRoute exact path="/profile" component={Dashboard} />
              <PrivateRoute exact path="/products" component={Dashboard} />
              <PrivateRoute exact path="/ads" component={Dashboard} />
              <PrivateRoute exact path="/brands" component={Dashboard} />
              <PrivateRoute exact path="/users" component={Dashboard} />
              <PrivateRoute exact path="/ads/new" component={Dashboard} />
              <PrivateRoute  path="/brands" component={Dashboard} />
               <PrivateRoute path="/donations" component={Dashboard} />
              <PrivateRoute  path="/category" component={Dashboard} />
              <PrivateRoute  path="/notifications" component={Dashboard} />
              <PrivateRoute  path="/category/subcategory-new" component={Dashboard} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
            </Switch>
          </AuthProvider>
        </Router>
    
    </Container>
    </IntlProvider>
  )
}

export default App
