import React, { useState, useEffect } from "react"
import { Card,Row, Col, Container, Button, Alert, Table, Image } from "react-bootstrap"
import { useAuth } from "../contexts/AuthContext"
import { Link, useHistory } from "react-router-dom"

import axios from '../axios';

export default function Donations() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth()
  const [donations, setDonations] = useState([])
  
  const history = useHistory()

   useEffect(() => {
    axios.get(`/donation`).then(res => {
      const donations = res.data;
      setDonations(donations)
    });
  }, []);


  async function handleDelete(donationId) {
    try {
      axios.delete(`/donation/${donationId}`).then(res => {
        const newdonations = donations.map(donation => {
          if(donation.id !== donationId){
            return donation;
            }
          }
        ).filter(item => item);
        setDonations(newdonations);
      });
    }catch {
      setError("Failed to delete donation")
    }
  }
  async function newDonation(){
    history.push("/donations/new")
  }

  async function editDonation(donationId) {
    history.push(`/donations/${donationId}/edit`)
  }
  async function donwloadCertificate(donation){
    const requestBody = {
      firstName: donation.donorName,
      lastName: "",
      carbon: donation.footPrints
    }
    try {
      axios.post(`http://167.86.119.145:3004/certificate`, requestBody,{
        responseType: 'blob',
        headers: {
        'Accept': 'application/pdf'
        }
      }).then(res => {
        var a = document.createElement('a');
            var url = window.URL.createObjectURL(res.data);
            a.href = url;
            a.download = 'footprint_certificate.pdf';
            document.body.append(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
      });
    }catch {
      setError("Failed to download certificate")
    }
  }

  async function handleApprove(donation) {
    try {
      donation.approved = true;
      axios.patch(`/donation/${donation.id}`, donation).then(res => {
        const updatedDonations = donations.map(don => {
          if(don.id  === donation.id){
            don.status = true;
          }
          return don;
        }).filter(item => item);
        setDonations(updatedDonations);
      });
    }catch {
      setError("Failed to update donation status")
    }
  }

   const list = donations.map((donation, index) => {
    return (
      <tr key={index}>
        <td>{index+1}</td>
        <td>{donation.donorName}</td>
        <td>{donation.description}</td>
        <td>{donation.location}</td>
        <td>{donation.collectionDate}</td>
        <td>{donation.prefferedHour}</td>
        <td>{donation.footPrints} </td>
        <td>{donation.approved ? 'Approved' : 'Not approved'}</td>
          <td className={'button-actions img'}>  <Button variant="success" onClick={() => handleApprove(donation)}>Approve</Button> {'    '}
   <Button variant="info" onClick={() => editDonation(donation.id)}>Edit</Button> {'    '}<Button variant="danger" onClick={() => handleDelete(donation.id)}>Delete</Button>{' '}
  <Button  onClick={() => donwloadCertificate(donation)}>Download Certificate</Button>
  </td>
    </tr>
    )
  })

  return ( 
  <div className="d-flex " style={{ minWidth: "100vw", marginLeft:"2rem" }}>
    <Row style={{ minWidth: "30vw" }}>
    <Row className="top-header">
    <h1> Donations </h1>
    <Button variant="success" onClick={newDonation}>New Donation</Button>
    </Row>
    <Table striped bordered hover responsive>
  <thead>
    <tr>
      <th>#</th>
      <th>Name</th>
      <th>Description</th>
      <th>Location</th>
      <th>Collection Date</th>
      <th>Preffered Hour</th>
      <th>Footrprints</th>
      <th>Status</th>
      <th>Actions</th>
    </tr>
  </thead>
  <tbody>
      {list}
  </tbody>
</Table>
    </Row>
    </div>
  )
}
